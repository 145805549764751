import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography, Button, TextField, Card, CardContent, Box, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { refreshAccessToken } from './RefreshToken';
import './LoginPage.css';
import account from 'src/_mock/account';
// import Card from 'src/theme/overrides/Card';
const accountWithUserName = {
  ...account,
  userName: sessionStorage.getItem('userName'),
};


// const StyledRoot = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex',
//   },
// }));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
   

  const handleLogin = async (event) => {
    event.preventDefault();

    const auth = btoa(`${username}:${password}`);
    const headers = new Headers({
      'Content-Type': 'application/json',
      Authorization: `${auth}`,
    });

    try {
      const response = await fetch('https://api.captcharts.com/api/users/admin', {
        method: 'POST',
        headers,
        body: JSON.stringify({ email: username, password }),
      });

      if (response.ok) {
        setLoginError('');

        const data = await response.json();
        const { accessToken, user } = data;
        // Store data in sessionStorage
        sessionStorage.setItem('accessToken', JSON.stringify(accessToken));

        console.log("accessToken",accessToken);

        sessionStorage.setItem('userName',user.name);
        sessionStorage.setItem('email', user.user_name);

      
        navigate('/dashboard');
      } else if (response.status === 403) {
        await refreshAccessToken();
      } else {
        const errorData = await response.json();
        setLoginError(errorData.message || 'Login failed.');
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError('An error occurred during login.');
    }
  };

 
  return (
    <>
      <Helmet>
        <title> Login | Captcharts</title>
      </Helmet>

      {/* <StyledRoot> */}
      <Container maxWidth="sm">
      <div className="LLogo common-pointer">
          <img
            src="../images/logo.png"
            alt="Logo"
          
          />
          </div>
      <Card className="Logincard">
          <CardContent className="L_cardcontent">
          <Typography variant="h4" gutterBottom>
            Login to Captcharts
          </Typography>

          <form onSubmit={handleLogin}>
            <TextField
              fullWidth
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
              margin="normal"
              required
            />

            <TextField
                fullWidth
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                margin="normal"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            {loginError && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {loginError}
              </Typography>
            )}
            <Box className="LButton">
            <Button type="submit" fullWidth  variant="contained" size="large" sx={{ mt: 3 }}
             style={{ backgroundColor: '#CF796C', color: '#FFFFFF' }}>
              Login
            </Button>
            </Box>
          </form>
          
          </CardContent>
          </Card>
      </Container>
      {/* </StyledRoot> */}
    </>
  );
};

export default LoginPage;
